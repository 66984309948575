<template>
  <div class="panel-body bg-white">
    <div class="row">
      <div class="panel panel-inverse col-lg-5" data-sortable-id="form-stuff-1">
        <!-- panel-body begin -->
        <div class="panel-body">
          <h1 class="page-header">
            请下载萤石App <small>(Webshell://1221)</small>
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    open() {
      window.location.href = "Webshell://1221";
    },
  },
  created() {
    this.open();
  },
};
</script>